import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';

import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import { APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';

import { maintenanceModeInitializer } from './maintenance-mode-initializer';

export function provideMaintenanceModeInitializer(): Provider {
	return {
		provide: APP_INITIALIZER,
		useFactory: maintenanceModeInitializer,
		deps: [HttpClient, OrganizationsStoreService, APP_ENVIRONMENT],
		multi: true
	};
}
