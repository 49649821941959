import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output, OnInit, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { Observable, filter, map } from 'rxjs';

import {
	DeviceClaimingApiService,
	InventoryDevicesApiService
} from '@shure/cloud/device-management/devices/data-access';
import {
	AccountInfoProjections,
	SwitchOrganizationModule
} from '@shure/cloud/shared/switch-organization/feature-switch-organization';
import { APP_ENVIRONMENT, AppEnvironment, getShureCloudUri } from '@shure/cloud/shared/utils/config';
import {
	PrismIconComponent,
	PrismNavButtonComponent,
	PrismNavComponent,
	PrismNavGroupComponent
} from '@shure/prism-angular-components';

import { MaterialIconsModule } from '../../modules/material-icons.module';

type NavigationItems = {
	icon: string;
	label: string;
	disabled: boolean;
	route: string;
	selected: boolean;
	buttonId: string;

	// A somewhat generic way to allow the dynamic updating of values in a nav label. The initial
	// use case is to include the count of claimed devies in the nav label. If this field
	// is left undefined, nothing happens.
	count$?: Observable<number>;
};

@Component({
	selector: 'sh-device-portal-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MaterialIconsModule,
		RouterModule,
		MatToolbarModule,
		MatDividerModule,
		TranslocoModule,
		SwitchOrganizationModule,
		PrismNavComponent,
		PrismNavGroupComponent,
		SwitchOrganizationModule,
		PrismNavButtonComponent,
		PrismIconComponent
	]
})
export class SidenavComponent implements OnInit {
	// when an item from the navlist is selected,we'll emit an event to the parent.
	// the parent can chose to do something with the event, such as close the sidenav.

	@Output() public navItemSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
	protected userSignedIn = false;

	public projections: AccountInfoProjections[] = ['preferences', 'organizationDetails'];

	public shureCloudUri = 'https://cloud.shure.com'; // gets set from environment, but default to prod URI in case env not provided
	public navItems: NavigationItems[] = [
		{
			icon: 'sh-network',
			label: 'cloud.device-management.navitems.devices', // will get translated in the HTML.
			disabled: false,
			route: 'devices',
			buttonId: 'sh-device-manager-sidenav-item-devices',
			selected: false,
			count$: this.inventoryDevicesApiService.getInventoryDevicesCount$()
		},
		{
			icon: 'help',
			label: 'cloud.device-management.navitems.pending-devices', // will get translated in the HTML.
			disabled: false,
			route: 'pending-devices',
			buttonId: 'sh-device-manager-sidenav-item-pending-devices',
			selected: false,
			count$: this.deviceClaimService.getPendingClaims$().pipe(map((pendingClaims) => pendingClaims.length))
		}
	];

	public showOrgSwitcher: boolean;

	constructor(
		private readonly inventoryDevicesApiService: InventoryDevicesApiService,
		private readonly deviceClaimService: DeviceClaimingApiService,
		protected readonly router: Router,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,
		private destroyRef: DestroyRef
	) {
		const shureCloudUri = getShureCloudUri(window.location.origin, this.appEnv.shureCloudUriMap);
		if (shureCloudUri) {
			this.shureCloudUri = shureCloudUri;
		}

		this.showOrgSwitcher = Boolean(this.appEnv.cdmFeatureFlags?.showOrgSwitcher);
	}

	public ngOnInit(): void {
		// Set initial active nav item on page load
		this.updateActiveNavItem();

		// Subscribe to navigation events to update active nav item
		this.router.events
			.pipe(
				filter((event): event is NavigationEnd => event instanceof NavigationEnd),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => {
				this.updateActiveNavItem();
			});
	}

	private updateActiveNavItem(): void {
		const activeNavItem = this.navItems.find(({ route }) =>
			this.router.isActive(`/${route}`, {
				paths: 'subset',
				queryParams: 'ignored',
				fragment: 'ignored',
				matrixParams: 'ignored'
			})
		);
		if (activeNavItem) {
			activeNavItem.selected = true;
		}
	}
}
