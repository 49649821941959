/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { isAccessToken } from '@okta/okta-auth-js';
import { of, Subject, takeUntil } from 'rxjs';

import { NotificationsEndpointApiService } from '@shure/cloud/shared/data-access/notifications';
import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';

import { RealTimeInAppBrowserNotificationService } from './services/real-time-in-app-browser-notification.service';
import { NotificationsPopupComponent } from './ui/components/notifications-popup/notifications-popup.component';

@Component({
	selector: 'sh-notifications-bell-icon-main',
	templateUrl: './notifications-bell-icon-main.component.html',
	styleUrl: './notifications-bell-icon-main.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsBellIconMainComponent implements OnInit, OnDestroy {
	@Input() public application: string | undefined = '';
	@Input() public notificationsWebSocketUrl: string | undefined = '';
	private destroy$: Subject<void> = new Subject<void>();
	public notificationsCount = 0;
	public totalNotificationsCount = 0;
	public nextPageToken?: string;

	constructor(
		@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
		public dialog: MatDialog,
		public cdr: ChangeDetectorRef,
		private notificationService: NotificationsEndpointApiService,
		private notificationsStore: NotificationsStore,
		private realTimeInAppBrowserNotificationService: RealTimeInAppBrowserNotificationService
	) {}

	public async ngOnInit(): Promise<void> {
		this.initializeNotifications();
	}

	public ngOnDestroy(): void {
		this.realTimeInAppBrowserNotificationService.disconnectWebSocket();
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Fetches notifications from the server and updates the notification store.
	 */
	public loadNotifications(nextPageToken?: string, filter?: string): void {
		if (!this.notificationService || !this.application) {
			return;
		}

		const params = {
			application: this.application,
			next: nextPageToken,
			filter: filter
		};

		this.notificationService
			.getNotifications$Response(params)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (notificationResponse) => {
					if (notificationResponse?.body?.items) {
						this.nextPageToken = notificationResponse?.body?.next;
						const notifications = notificationResponse.body.items;
						this.notificationsStore.isGetNotificationsCalled = true;
						this.notificationsStore.prependOrPatchNotifications(
							<NotificationStoreData[]>(<unknown>notifications)
						);
						this.totalNotificationsCount = this.totalNotificationsCount + notifications.length;
					}
				},
				error: () => of(null)
			});
	}

	public extractTextFromHtml(html: string): string {
		const span = document.createElement('span');
		span.innerHTML = html;
		return span.textContent || span.innerText || '';
	}

	/**
	 * Opens a dialog to display notifications in a popup.
	 * Adjusts dialog position and size based on screen width.
	 */
	public notificationsPopup(): void {
		const dialogConfig = new MatDialogConfig();
		// Position the dialog
		dialogConfig.position = {
			top: '60px',
			right: '16px'
		};
		dialogConfig.hasBackdrop = false;
		dialogConfig.panelClass = 'sh-custom-dialog-container';

		// Set width based on screen size
		const screenWidth = window.innerWidth;
		if (screenWidth < 600) {
			// Mobile
			dialogConfig.width = '90%';
		} else if (screenWidth < 960) {
			// Tablet
			dialogConfig.width = '50%';
		} else {
			// Desktop
			dialogConfig.width = '360px';
			dialogConfig.position = {
				top: '50px',
				right: '81px'
			};
		}

		dialogConfig.maxWidth = '100vw';
		dialogConfig.maxHeight = '80vh';
		dialogConfig.height = 'auto';
		dialogConfig.data = { application: this.application };

		this.dialog.open(NotificationsPopupComponent, dialogConfig);
	}

	/**
	 * Sets up notifications functionality, including subscriptions and WebSocket connection.
	 * Called when notifications are enabled.
	 */
	private initializeNotifications(): void {
		// Subscribe to the unread notifications count
		this.notificationsStore.unreadNotificationsCount$
			.pipe(takeUntil(this.destroy$))
			.subscribe((unreadNotificationsCount) => {
				this.notificationsCount = unreadNotificationsCount;
				if (this.nextPageToken && this.totalNotificationsCount !== 500) {
					this.loadNotifications(this.nextPageToken);
				}
				this.cdr.detectChanges();
			});
		// Load notifications via API
		this.loadNotifications();
		this.oktaAuth.tokenManager.get('accessToken').then((tokenData) => {
			if (isAccessToken(tokenData) && tokenData?.accessToken) {
				this.realTimeInAppBrowserNotificationService.connectWebSocket({
					application: this.application,
					token: tokenData.accessToken,
					notificationsWebSocketUrl: this.notificationsWebSocketUrl
				});
			}
		});
	}
}
