import { Component, Inject, Injector, OnInit, HostListener } from '@angular/core';
import { runInInjectionContext } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgHttpCachingService } from 'ng-http-caching';
import { Subject } from 'rxjs';

import { monitorLoginState, OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LanguagesService } from '@shure/shared/angular/utils/i18n';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { environment } from '../environments/environment';

@Component({
	selector: 'sh-app-root',
	templateUrl: './app.component.html',
	styleUrls: []
})
export class AppComponent implements OnInit {
	public appVersion: string;
	public clientVersion: string | undefined;

	private destroy = new Subject<boolean>();

	constructor(
		private readonly languageService: LanguagesService,
		private metaTagService: Meta,
		public oktaIntf: OktaInterfaceService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,
		private injector: Injector,
		private readonly logger: ILogger,
		private ngHttpCachingService: NgHttpCachingService
	) {
		this.logger = logger.createScopedLogger('CDM AppComponent');
		this.appVersion = this.appEnv.appVersion;
		this.clientVersion = this.appEnv.clientVersion;

		monitorLoginState(oktaIntf, {
			onLogIn: this.onLogIn.bind(this),
			onLogOut: this.onLogOut.bind(this)
		});
	}

	//
	// The purpose of this code is to handle the case when a user has both
	// Shure Cloud tabs and CDM tabs opened, and then logs out of the Shure Cloud.
	// When the user clicks back into the CDM window, we'll check to see if the
	// session still exists, and if not logout the user.
	//
	// window:focus events are triggered only the first time the user clicks
	// in a window after having the cursor focused elsewhere.
	//
	@HostListener('window:focus', ['$event'])
	public onFocus(_event: FocusEvent): void {
		// the oktaSessionGuard must run in an injection context, which is why this
		// code isn't a simple function call.
		runInInjectionContext<void>(this.injector, () =>
			oktaSessionGuard(<ActivatedRouteSnapshot>{}, <RouterStateSnapshot>{})
		);
	}

	public ngOnInit(): void {
		/* no-op comment to trigger pipelin build */
		this.languageService.getPreferredCultureCode();
		if (environment.production) {
			this.metaTagService.addTags([{ name: 'robots', content: 'index' }]);
		} else {
			this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
		}
	}

	private onLogIn(): void {
		this.destroy = new Subject();
		this.ngHttpCachingService.clearCache();
	}

	private onLogOut(): void {
		this.destroy.next(true);
		this.destroy.complete();
	}
}
