<mat-drawer-container class="appContainer" [hasBackdrop]="isSmallDevice()">
	<mat-drawer
		class="sideNav"
		#sideNavHandle
		[mode]="isSmallDevice() ? 'over' : 'side'"
		[opened]="!isSmallDevice() && (oktaIntf.$isUserAuthenticated | async) === true"
	>
		<sh-device-portal-sidenav *ngIf="(oktaIntf.$isUserAuthenticated | async) === true"></sh-device-portal-sidenav>
	</mat-drawer>

	<mat-drawer-content class="sideNavContent">
		<sh-header-toolbar
			*ngIf="(oktaIntf.$isUserAuthenticated | async) === true"
			[showSidenavToggleButton]="true"
			[showFeatureButton]="true"
			[showNotifications]="showNotifications"
			(toggleSidenav)="sideNavHandle.toggle()"
		></sh-header-toolbar>
		<router-outlet></router-outlet>
	</mat-drawer-content>
</mat-drawer-container>
